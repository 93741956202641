import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

import Home from "../views/home/Home.vue";
import Auth from "../views/auth/Auth.vue";
import Counters from "../views/counters/Counters.vue";
import Reports from "../views/reports/Reports.vue";
import Users from "../views/users/Users.vue";
import Attendance from "../views/attendance/Attendance.vue";
import Calendar from "../views/calendar/Calendar.vue";
import Audiences from "../views/audiences/Audiences.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/attendance",
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: Auth,
  },
  {
    path: "/home",
    name: "Home",
    meta: { requiresAuth: true },
    component: Home,
  },
  {
    path: "/counters",
    meta: {
      requiresAuth: true,
      requiresPermission: "desks",
    },
    component: Counters,
    children: [
      {
        path: "",
        name: "Counters",
        meta: { requiresAuth: true, requiresPermission: "desks" },
        component: () =>
          import("../views/counters/children/counters/Counters.vue"),
      },
      {
        path: "courts",
        name: "Courts",
        meta: { requiresAuth: true, requiresPermission: "subgroups" },
        component: () => import("../views/counters/children/courts/Courts.vue"),
      },
      {
        path: "jurisdictions",
        name: "Jurisdictions",
        meta: {
          requiresAuth: true,
          requiresPermission: "groups",
        },
        component: () =>
          import("../views/counters/children/jurisdictions/Jurisdictions.vue"),
      },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    meta: {
      requiresAuth: true,
      requiresPermission: "reports",
    },
    component: Reports,
    children: [
      {
        path: "",
        name: "QueueAttendance",
        meta: { requiresAuth: true, requiresPermission: "reports" },
        component: () =>
          import(
            "../views/reports/children/queueAttendance/QueueAttendance.vue"
          ),
      },
      {
        path: "time-attendance",
        name: "TimeAttendance",
        meta: { requiresAuth: true, requiresPermission: "reports" },
        component: () =>
          import("../views/reports/children/timeAttendance/TimeAttendance.vue"),
      },
      {
        path: "satisfaction",
        name: "Satisfaction",
        meta: { requiresAuth: true, requiresPermission: "reports" },
        component: () =>
          import("../views/reports/children/satisfaction/Satisfaction.vue"),
      },
      {
        path: "queue-quit",
        name: "Quit",
        meta: { requiresAuth: true, requiresPermission: "reports" },
        component: () => import("../views/reports/children/quit/Quit.vue"),
      },
      {
        path: "queue-waiting-time",
        name: "QueueWaitingTime",
        meta: { requiresAuth: true, requiresPermission: "reports" },
        component: () =>
          import(
            "../views/reports/children/queueWaitingTime/QueueWaitingTime.vue"
          ),
      },
    ],
  },
  {
    path: "/users",
    name: "Users",
    meta: {
      requiresAuth: true,
      requiresPermission: "people",
    },
    component: Users,
  },
  {
    path: "/attendance",
    meta: { requiresAuth: true, requiresPermission: "attendances" },
    component: Attendance,
    children: [
      {
        path: ":counterIDs?",
        name: "Queue",
        redirect: "/attendance/:counterIDs?/waiting",
        meta: { requiresAuth: true, requiresPermission: "attendances" },
        component: () => import("../views/attendance/children/queue/Queue.vue"),
        children: [
          {
            path: "waiting",
            name: "Waiting",
            meta: { requiresAuth: true, requiresPermission: "attendances" },
            component: () =>
              import(
                "../views/attendance/children/queue/children/waiting/Waiting.vue"
              ),
          },
          {
            path: "in-progress",
            name: "InProgress",
            meta: { requiresAuth: true, requiresPermission: "attendances" },
            component: () =>
              import(
                "../views/attendance/children/queue/children/inProgress/InProgress.vue"
              ),
          },
        ],
      },
      {
        path: "chat/:attendanceID",
        name: "Chat",
        meta: { requiresAuth: true },
        component: () => import("../views/attendance/children/chat/Chat.vue"),
      },
    ],
  },
  {
    path: "/calendar/:calendarID",
    name: "Calendar",
    meta: {
      requiresAuth: true,
      requiresPermission: "desks",
    },
    component: Calendar,
  },
  {
    path: "/audiences",
    component: Audiences,
    meta: {
      requiresAuth: true,
      requiresPermission: "audiences",
    },
    children: [
      {
        path: "",
        name: "Audiences",
        meta: {
          requiresAuth: true,
          requiresPermission: "audiences",
        },
        component: () =>
          import("../views/audiences/children/audiences/Audiences.vue"),
      },
      {
        path: "details/:id",
        name: "AudienceDetails",
        meta: {
          requiresAuth: true,
          requiresPermission: "audiences",
        },
        component: () =>
          import(
            "../views/audiences/children/audienceDetails/AudienceDetails.vue"
          ),
      },
      {
        path: "chat",
        name: "AudienceChat",
        meta: {
          requiresAuth: true,
          requiresPermission: "audiences",
        },
        component: () =>
          import("../views/audiences/children/audienceChat/AudienceChat.vue"),
      },
    ],
  },
  {
    path: "/entities",
    meta: {
      requiresAuth: true,
      requiresPermission: "entities",
    },
    component: () => import("../views/entities/Entities.vue"),
    children: [
      {
        path: "",
        name: "Entities",
        meta: {
          requiresAuth: true,
          requiresPermission: "audiences",
        },
        component: () =>
          import("../views/entities/children/entities/Entities.vue"),
      },
      {
        path: "waiting-room/:id",
        name: "WaitingRoom",
        meta: {
          requiresAuth: true,
          requiresPermission: "audiences",
        },
        component: () =>
          import("../views/entities/children/waitingRoom/WaitingRoom.vue"),
      },
    ],
  },
  {
    path: "/permissions",
    name: "Permissions",
    meta: {
      requiresAuth: true,
      requiresPermission: "permission-groups",
    },
    component: () => import("../views/permissions/Permissions.vue"),
  },
  {
    path: "*",
    redirect: "/attendance",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
