<template>
  <router-view />
</template>

<script>
export default {
  name: "Audiences",
};
</script>

<style src="./style.scss" lang="scss" scoped />
