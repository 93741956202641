import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";
import { connectSocket, disconnectSocket } from "@/socket";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "admin-bv-default",
  storage: window.localStorage,
  modules: ["accessToken", "refreshToken", "user", "filters", "attendance"],
});

export default new Vuex.Store({
  state: {
    accessToken: "",
    refreshToken: "",
    deviceToken: "",
    user: null,
    attendance: null,
    audience: null,
    filters: {
      groups: [],
      subgroups: [],
      desks: [],
      attendants: [],
    },
  },

  getters: {
    authenticated: ({ accessToken, refreshToken, user }) =>
      !!accessToken && !!refreshToken && !!user,
    accessToken: ({ accessToken }) => accessToken,
    refreshToken: ({ refreshToken }) => refreshToken,
    deviceToken: ({ deviceToken }) => deviceToken,
    user: ({ user }) => user,
    attendance: ({ attendance }) => attendance,
    audience: ({ audience }) => audience,
    attendanceRole: ({ user }) => {
      // operator (attendant), moderator (coordinator), admin (director)

      if (!user?.permissionGroups) return null;

      const result = user.permissionGroups.find(
        (group) => !!group.role && group.module === "attendances"
      );
      return result ? result.role : null;
    },
    audienceRole: ({ user }) => {
      if (!user?.permissionGroups) return null;

      const result = user.permissionGroups.find(
        (group) => !!group.role && group.module === "audiences"
      );
      return result ? result.role : null;
    },
  },

  mutations: {
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload;
    },
    SET_DEVICE_TOKEN(state, payload) {
      state.deviceToken = payload;
    },
    SET_REFRESH_TOKEN(state, payload) {
      state.refreshToken = payload;
    },
    SET_ACCOUNT(state, payload) {
      state.user = payload;
    },
    SET_FILTERS_GROUPS(state, payload) {
      state.filters.groups = payload;
    },
    SET_FILTERS_SUBGROUPS(state, payload) {
      state.filters.subgroups = payload;
    },
    SET_FILTERS_DESKS(state, payload) {
      state.filters.desks = payload;
    },
    SET_FILTERS_ATTENDANTS(state, payload) {
      state.filters.attendants = payload;
    },
    SET_ATTENDANCE(state, payload) {
      state.attendance = payload;
    },
    SET_AUDIENCE(state, payload) {
      state.audience = payload;
    },
  },

  actions: {
    setAccessToken({ commit }, payload) {
      commit("SET_ACCESS_TOKEN", payload);
      api.defaults.headers.common["Authorization"] = payload;
    },
    setRefreshToken({ commit }, payload) {
      commit("SET_REFRESH_TOKEN", payload);
    },
    setAdminStatus({ commit }, payload) {
      commit("SET_ADMIN_STATUS", payload);
    },
    setDeviceToken({ commit }, payload) {
      commit("SET_DEVICE_TOKEN", payload);
    },
    setUser({ commit }, payload) {
      commit("SET_ACCOUNT", payload);
    },
    setSignIn({ dispatch }, payload) {
      dispatch("setAccessToken", payload.accessToken);
      dispatch("setRefreshToken", payload.refreshToken);
      dispatch("setDeviceToken", payload.deviceToken);
      dispatch("setUser", payload.user);

      connectSocket();
    },
    setSignOut({ dispatch }) {
      dispatch("setAccessToken", "");
      dispatch("setRefreshToken", "");
      dispatch("setUser", null);
      dispatch("setFiltersGroups", []);
      dispatch("setFiltersSubgroups", []);
      dispatch("setFiltersDesks", []);
      dispatch("setFiltersAttendants", []);

      disconnectSocket();
    },
    setFiltersGroups({ commit }, payload) {
      commit("SET_FILTERS_GROUPS", payload);
    },
    setFiltersSubgroups({ commit }, payload) {
      commit("SET_FILTERS_SUBGROUPS", payload);
    },
    setFiltersDesks({ commit }, payload) {
      commit("SET_FILTERS_DESKS", payload);
    },
    setFiltersAttendants({ commit }, payload) {
      commit("SET_FILTERS_ATTENDANTS", payload);
    },
    setAttendance({ commit }, payload) {
      commit("SET_ATTENDANCE", payload);
    },
    setAudience({ commit }, payload) {
      commit("SET_AUDIENCE", payload);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
